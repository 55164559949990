import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { ProdutoCard } from '@core/models/produto.models';
import { ConsultaService } from '@core/services/consulta.service';
import { MatPageable } from '@core/table/mat-table';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-favorito',
  templateUrl: './favorito.component.html',
  styleUrls: ['./favorito.component.scss']
})
export class FavoritoComponent implements OnInit {

  ramos: string[] = ['Todos', 'Automóvel', 'Habitacional', 'Multiramo', 'Saúde', 'Previdência', 'Vida'];
  produtos: ProdutoCard[] = [];
  filtro: FiltroProdutosUsuario = {favorito: 1};
  loadingProducts: boolean = false

  // NavTabs
  active: number = 0

  constructor(
    private router: Router,
    private consultaService: ConsultaService,
    private appSharedDataService: AppSharedDataService,
    private _storageService: StorageService,) { }

  ngOnInit() {
    this.listarFavoritos(true)    
  }

  listarFavoritos(loading: boolean, idRamo?: number){
    this.loadingProducts = loading
    const pagination: MatPageable = {query: ''} 
    this.filtro.idRamo = idRamo ? idRamo : undefined
    this.consultaService.getProdutosConsultaCrud(pagination, this.filtro).subscribe((res) => {
      this.produtos = res
      this.loadingProducts = false

      if(!idRamo){
        this._storageService.set({ storageKey: EStorage.FAVORITES, data: res, expire: moment().add(1, 'hour').toDate() })
      }
    })
  }


  onDesFavorite(idProduto: number): void {
    this.consultaService.desFavoritarProduto(idProduto).subscribe((data) => this.listarFavoritos(false));
  }

  btnHome(): void {
    this.appSharedDataService.setResetMenuBootstrap(true);
    this.router.navigate(['/dashboard']);
  }

  goToMetodo(produto: ProdutoCard, metodo: any){
    const storageMenuValue = this._storageService.get({storageKey: EStorage.MENU})
    let ramos: any[]
    let ramoId: string
    if(storageMenuValue){
      ramos = storageMenuValue.value
    }

    for(let ramo of ramos){
      const foundRamo = ramo.produtos.find(pdt => pdt.idProduto == produto.idProduto)
      if(foundRamo) ramoId = ramo.id
    }

    this.router.navigate(['/consulta', ramoId, produto.idProduto, metodo.id, produto.dsServico, metodo.dsMetodo], { queryParamsHandling: 'merge', queryParams: { favorito: produto.favorito } });
  }
}
