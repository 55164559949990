import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { ProdutoCard } from '@core/models/produto.models';
import { ConsultaService } from '@core/services/consulta.service';
import { MatPageable } from '@core/table/mat-table';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';
import moment from 'moment';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-dash-favorites',
  templateUrl: './dash-favorites.component.html',
  styleUrls: ['./dash-favorites.component.scss']
})
export class DashFavoritesComponent implements OnInit {

  produtos: ProdutoCard[] = [];

  @ViewChild('containerProp') containerProp!: ElementRef;
  scrollActiveHeight: number = 0;


  pageableProduto = new MatPageable(0, 12);
  filtro: FiltroProdutosUsuario = { favorito: 1 };
  constructor(private consultaService: ConsultaService, private _storageService: StorageService, private _router: Router, public appSharedDataService: AppSharedDataService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.produtos = this._storageService.get({ storageKey: EStorage.FAVORITES })?.value

    if (!(this.produtos && this.produtos.length)) {
      this.consultaService.getProdutosConsultaCrud(this.pageableProduto, this.filtro).subscribe((data) => {
        this.produtos = data
        this._storageService.set({ storageKey: EStorage.FAVORITES, data: data, expire: moment().add(1, 'hour').toDate() })
      })

    }
  }

  ngAfterViewInit(): void {
    this.updateScrollActiveHeight()
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateScrollActiveHeight();
  }

  updateScrollActiveHeight(): void {
    if (this.containerProp?.nativeElement) {
      this.scrollActiveHeight = this.containerProp.nativeElement.offsetHeight - 100;
      this.cdr.detectChanges();
    }
  }

  navigateTo(produto: any) {
    const storageMenuValue = this._storageService.get({ storageKey: EStorage.MENU })
    let ramos: any[]
    let ramoId: string
    if (storageMenuValue) {
      ramos = storageMenuValue.value
    }

    for (let ramo of ramos) {
      const foundRamo = ramo.produtos.find(pdt => pdt.idProduto == produto.idProduto)
      if (foundRamo) ramoId = ramo.id
    }

    const url = `/consulta/${ramoId}/${produto.idProduto}/${produto.metodos[0].id}/${produto.dsServico}/${produto.metodos[0].dsMetodo}?favorito=true`
    this._router.navigateByUrl(url)
  }

}
