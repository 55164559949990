import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'acessoNaoPermitido', loadChildren: () => import('./modules/acesso-nao-permitido/acesso-nao-permitido.module').then(mod => mod.AcessoNaoPermitidoModule) },
  {
    path: 'consulta/:idRamo/:idProduto/:idMetodo/:dsServico/:dsMetodo', data: {
      reuse: true
    }, loadChildren: () => import('./modules/consulta/consulta.module').then(mod => mod.ConsultaModule)
  },
  { path: 'contratos', loadChildren: () => import('./modules/contrato/contrato.module').then(mod => mod.ContratoModule) },
  { path: 'empresas', loadChildren: () => import('./modules/empresa/empresa.module').then(mod => mod.EmpresaModule) },
  { path: 'funcionalidade', loadChildren: () => import('./modules/funcionalidade/funcionalidade.module').then(mod => mod.FuncionalidadeModule) },
  { path: 'grupo-economico', loadChildren: () => import('./modules/grupo-economico/grupo-economico.module').then(mod => mod.GrupoEconomicoModule) },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule) },
  { path: 'lote', loadChildren: () => import('./modules/lote/lote.module').then(mod => mod.LoteModule) },
  { path: 'perfil', loadChildren: () => import('./modules/meu-perfil/meu-perfil.module').then(mod => mod.MeuPerfilModule) },
  { path: 'minhas-consultas', loadChildren: () => import('./modules/minhas-consultas/minhas-consultas.module').then(mod => mod.MinhasConsultasModule) },
  { path: 'parametros-portal', loadChildren: () => import('./modules/parametro-portal/parametro-portal.module').then(mod => mod.ParametroPortalModule) },
  { path: 'produto', loadChildren: () => import('./modules/produto/produto.module').then(mod => mod.ProdutoModule) },
  { path: 'ramo', loadChildren: () => import('./modules/ramo/ramo.module').then(mod => mod.RamoModule) },
  { path: 'relatorio', loadChildren: () => import('./modules/relatorio/relatorio.module').then(mod => mod.RelatorioModule) },
  { path: 'test', loadChildren: () => import('./modules/test/test.module').then(mod => mod.TestModule) },
  { path: 'tipo-contrato', loadChildren: () => import('./modules/tipo-contrato/tipo-contrato.module').then(mod => mod.TipoContratoModule) },
  { path: 'tipo-empresa', loadChildren: () => import('./modules/tipo-empresa/tipo-empresa.module').then(mod => mod.TipoEmpresaModule) },
  { path: 'tipo-produto', loadChildren: () => import('./modules/tipo-produto/tipo-produto.module').then(mod => mod.TipoProdutoModule) },
  { path: 'usuarios', loadChildren: () => import('./modules/usuario/usuario.module').then(mod => mod.UsuarioModule) },
  { path: 'manual', loadChildren: () => import('./modules/manual/manual.module').then(mod => mod.ManualModule) },
  { path: 'controle-enquadramento', loadChildren: () => import('./modules/controle-enquadramento/controle-enquadramento.module').then(mod => mod.ControleEnquadramentoModule) },
  { path: 'publico', loadChildren: () => import('@modules/public/public.module').then(mod => mod.PublicModule) },
  { path: 'market-share', loadChildren: () => import('@modules/market-share/market-share.module').then(mod => mod.MarketShareModule) },
  { path: 'faturamento', loadChildren: () => import('@modules/faturamento/faturamento.module').then(mod => mod.FaturamentoModule) },
  { path: 'cadastro', loadChildren: () => import('@modules/cadastro/cadastro.module').then(mod => mod.CadastroModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
