import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProdutoCard, MetodoCard } from '@core/models/produto.models';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetalheProdutoComponent } from '../detalhe-produto/detalhe-produto.component';
import { AuthenticationService } from '@core/auth/authentication.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as star } from '@fortawesome/free-regular-svg-icons';
import { NotificationService } from '@core/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TipoProdutoEnum } from '@core/models/tipo-produto.models';
import { MetodoEnum } from '@core/models/metodo.models';
import { AppSharedDataService } from 'src/app/app-shared-data.service';
import { SivveService } from '@core/services/sivve.service';
import { DashboardService } from '@core/services/dashboard.service';

@Component({
  selector: 'card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.scss']
})
export class CardProdutoComponent implements OnInit {

  @Input() produto: ProdutoCard;
  @Input() idRamo: number;
  @Output() onFavorite = new EventEmitter<number>();
  @Output() onDesFavorite = new EventEmitter<number>();

  favorito = faStar;
  naoFavorito = star;
  hover: boolean;
  modal: any;
  itensMenuPbi: any[] = [];

  constructor(private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private _sanitizer: DomSanitizer,
    private appSharedDataService: AppSharedDataService,
    private sivveService: SivveService,
    private dashboardService: DashboardService) {
  }

  ngOnInit() {
    if (this.produto.metodos) {
      let produtosSivve = this.produto.metodos.filter(x => [MetodoEnum.SivvePlusCPF, MetodoEnum.SivvePlusCNPJ, MetodoEnum.ConsultaAvancada].indexOf(x.id) != -1);
      produtosSivve = produtosSivve.concat(this.produto.metodos.filter(x => [MetodoEnum.SivveQuadroPessoalV2].indexOf(x.id) != -1));
      if (produtosSivve.length) {
        this.produto.metodos = produtosSivve.concat(this.produto.metodos.filter(x => [MetodoEnum.SivvePlusCPF, MetodoEnum.SivvePlusCNPJ, MetodoEnum.ConsultaAvancada, MetodoEnum.SivveQuadroPessoalV2].indexOf(x.id) == -1))
        this.produto.metodos.filter(x => x.id == MetodoEnum.ConsultaAvancada && !x.acesso);
      }
    }

   this.itensMenuPbi = this.dashboardService.powerBiMenu

  }


  favorite(): void {
    this.onFavorite.emit(this.produto.idProduto);
  }

  desfavorite(): void {
    this.onDesFavorite.emit(this.produto.idProduto);
  }

  goToConsulta(produto: any, metodo: any, ramo): void {

    if (produto.idProduto == 80 && metodo) {
      let reportNamePBI = this.itensMenuPbi.find((x: any) => x.id == metodo.id);
      sessionStorage.setItem('reportNamePBI', reportNamePBI.subItem[0].nameReport);
    }

    if (this.produto.acesso && metodo.acesso) {
      this.appSharedDataService.setMetodoAtivo({ ramo: this.idRamo, produto: this.produto, metodo: metodo });
      this.router.navigate(['/consulta', this.idRamo, this.produto.idProduto, metodo.id, this.produto.dsServico, metodo.dsMetodo], { queryParamsHandling: 'merge', queryParams: { favorito: this.produto.favorito } });
      this.hover = false;
    }
    else
      this.notificationService.info('Você não possui permissão para realizar esta consulta!!!');
  }

  openInfo(): void {
    this.hover = true;
    this.modal = this.modalService.open(DetalheProdutoComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      centered: true
    });

    let instance = <DetalheProdutoComponent>this.modal.componentInstance;
    instance.descricao = this.produto.descricaoDetalhada;
    this.modal.result.then((data) => {
      this.hover = false;
      this.modal = undefined;
    })
  }

  onHover(): void {
    this.hover = true;
  }

  onLeave(): void {
    if (!this.modal)
      this.hover = false;
  }

  getImage() {
    return this._sanitizer.bypassSecurityTrustStyle(`url('${this.produto.imgProduto}')`);
  }

  get showMetodos() {
    return this.produto.idTipoProduto == TipoProdutoEnum.Padrao;
  }

  showMetodo(metodo): boolean {
    if (metodo.id == MetodoEnum.ConsultaAvancada && !metodo.acesso)
      return false;

    return true;
  }

  get showLinkExterno() {
    return this.produto.idTipoProduto == TipoProdutoEnum.LinkExterno;
  }

  goToLinkExterno(): void {
    if (!this.acesso)
      this.notificationService.info('Você não possui permissão para acessar este produto!!!');
  }

  get acesso(): boolean {
    return this.produto.acesso || this.produto.idTipoProduto == TipoProdutoEnum.LinkExterno;
  }
}
