// let value = '9BWZiZ02Q8dAA1234';
const i = /[iI]/g; // Letras i = minúscula e maíscula;
const o = /[oO]/g; // Letras o = minúscula e maíscula.
const q = /[qQ]/g; // Letras q = minúscula e maíscula.

const ultimosQuatroDigitos = /^[0-9]{4}$/; // Ultimos 4 digitos precisam ser números

const tamTotal = 17; // Tamanho total do valor.
const somenteNum = /^(?![0-9]+$).*/; //Não permitir chassi somente com valor numérico
const somenteLetras = /^(?![a-zA-Z]+$).*/; //Não permitir chassi somente com valor alfabético

// Validar se há somente números
const verificaSomenteNum = (string) => somenteNum.test(string);

// Validar se há somente letras
const verificaSomenteLetras = (string) => somenteLetras.test(string);

// Se possuir o número “0” (ZERO) como 1º dígito ou Q estiver no começo do chassi, então seguir com a mensagem de erro.
const verificaPrimeiroDigito = (string) => /^[a-p-r-zA-P-R-Z1-9]/g.test(string);


// Se o Q estiver no começo do chassi, não pode ser substituído pelo numeral 0, então seguir com a mensagem de erro.
const verificaPrimeiroDigitoLetraQ = (string) => /^[a-p-r-zA-P-R-Z0-9]/g.test(string);

// Se possuir o número “0” (ZERO) como 1º dígito, então seguir com a mensagem de erro.
const verificaPrimeiroDigitoNumer0 = (string) => /^[a-zA-Z1-9]/g.test(string);

// Os quatro últimos caracteres devem ser obrigatoriamente numéricos
const verificaQuatroUltimosDigitos = (string) => ultimosQuatroDigitos.test(string.slice(-4));

// Mudar 'ItemDe' para 'ItemPara' do 'Valor' recebido
const validaLetra = (valor: string, itemDe: any, itemPara: any) => valor.replace(itemDe, itemPara.toString());

// Validar se tem espaco
const verificaEspaco = (string) => /\s/g.test(string);

// Não pode haver repetições consecutivas por mais de 6 vezes do mesmo caractere (numérico ou alfabético) a partir do 4º dígito.
const validarRepeticoes = (string) => {
    let ini = 4;
    let fim = 10;
    let isValid = true;
    do {
        string = string.toUpperCase();
        let truncated_text = string.slice(ini, fim);
        if (
            truncated_text[0] == truncated_text[1] &&
            truncated_text[1] == truncated_text[2] &&
            truncated_text[2] == truncated_text[3] &&
            truncated_text[3] == truncated_text[4] &&
            truncated_text[4] == truncated_text[5]
        ) {
            isValid = false;
        }
        ini++;
        fim++;
    } while (fim <= string.length && isValid);
    return isValid;
};

const validarCaracteresInvalidos = (string) => /[iIoOqQ]/.test(string);

let erros: any[] = [];
let infos: any[] = [];

const mensagemErro = (string) => erros.push(string);
const mensagemInfo = (string) => infos.push(string);

//Validacao Geral
export const validarRegraChassi = (value) => {
    erros = [];
    infos = [];
    let chassiModificado = undefined;
    let chassiEnviado = value;

    if (value.length == tamTotal) {

        // Valida se o chassi é apenas numérico
        verificaSomenteNum(value) ? value : mensagemErro('O Chassi não pode ser apenas numérico');

        // Valida se o chassi é apenas alfabético
        verificaSomenteLetras(value) ? value : mensagemErro('O Chassi não pode ser apenas alfabético');

        // Os Quatro últimos caracteres devem ser obrigatoriamente numéricos
        verificaQuatroUltimosDigitos(value) ? value : mensagemErro('Os quatro últimos caracteres devem ser obrigatoriamente numéricos');

        // Possuir o número “0” (ZERO) como 1º dígito:
        verificaPrimeiroDigitoNumer0(value) ? value : mensagemErro('Possui o número “0” (ZERO) como 1º dígito');

        // Se o Q estiver no começo do chassi, não pode ser substituído pelo numeral 0, então seguiríamos com a mensagem de erro.
        verificaPrimeiroDigitoLetraQ(value) ? value : mensagemErro('Letra "Q" está no começo do chassi');

        // Não pode haver repetições consecutivas por mais de 6 vezes do mesmo caractere (numérico ou alfabético) a partir do 4º dígito.
        validarRepeticoes(value) ? value : mensagemErro('Não pode haver repetições consecutivas por mais de 6 vezes do mesmo caractere (numérico ou alfabético) a partir do 4º dígito.');

        // Substituição automática da letra “i” pelo número “1” (um);
        // i.test(value) ? ((value = validaLetra(value, i, 1)) ? mensagemInfo('Substituição automática da letra “i” pelo número “1” (um).') : value) : value

        // Substituição Automática das letras “o” para o numeral 0.
        // o.test(value) ? ((value = validaLetra(value, o, 0)) ? mensagemInfo('Substituição Automática da letra “o” pelo número “0” (zero)') : value) : value

        // Substituição Automática das letras “q” para o numeral 0.
        // q.test(value) ? ((value = validaLetra(value, q, 0)) ? mensagemInfo('Substituição Automática da letra “q” pelo número “0” (zero)') : value) : value

        validarCaracteresInvalidos(value) ? mensagemErro('O CHASSI não deve conter as letras "i", "o" e "q"') : value;

        // Valida se tem espaço em branco
        verificaEspaco(value) ? ((value = value.replace(' ', '')) ? mensagemErro('O CHASSI não pode ter espaço entre os caracteres') : value) : value

        chassiEnviado === value ? chassiModificado = undefined : chassiModificado = value;

    } else if (value.length < tamTotal) {
        mensagemErro('Sugestão: “CHASSI com menos de 17 caracteres, favor enviar consulta por placa”');
    } else {
        mensagemErro('Erro: Chassi informado é invalido!');
    }

    let isValid;
    (erros.length) ? isValid = false : isValid = true;

    let objRetono = {
        erros: erros,
        infos: infos,
        chassiEnviado: chassiEnviado,
        chassiModificado: chassiModificado,
        valido: isValid
    }

    return objRetono;

}



export class RegraChassi { }
