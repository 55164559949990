import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';

@Component({
  selector: 'app-dash-recent-activity',
  templateUrl: './dash-recent-activity.component.html',
  styleUrls: ['./dash-recent-activity.component.scss'],
})
export class DashRecentActivityComponent implements OnInit, AfterViewInit {
  @ViewChild('containerProp') containerProp!: ElementRef;

  activities: { historico: { date: Date; url: string }[] } = { historico: [] };
  scrollActiveHeight: number = 0;

  constructor(
    private _localStorageService: LocalStorageService,
    private storageService: StorageService,
    private _router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activities = JSON.parse(
      this._localStorageService.getItem('atividade-recente')
    ) || { historico: [] };

    if (this.activities) this.findProducts();
  }
  
  ngAfterViewInit(): void {
    this.updateScrollActiveHeight()
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateScrollActiveHeight();
  }

  updateScrollActiveHeight(): void {
    if (this.containerProp?.nativeElement) {
      this.scrollActiveHeight = this.containerProp.nativeElement.offsetHeight - 100;
      this.cdr.detectChanges();
    }
  }

  findProducts(): void {
    const ramos = this.storageService.get({ storageKey: EStorage.MENU }).value as Array<any>;
    let produtosFull: any[] = [];

    for (let ramo of ramos) {
      for (let produto of ramo.produtos) {
        produtosFull.push(produto);
      }
    }

    const mapedMetodos = this.activities.historico.map((activity) => {
      const regexConsultaProdutoMetodo = /\/consulta\/(\d+)\/(\d+)\/(\d+)\//;

      const matches = activity.url.match(regexConsultaProdutoMetodo);
      const produtoId = matches ? matches[2] : null;
      const metodoId = matches ? matches[3] : null;

      const produto = produtosFull.find((el) => el.idProduto == produtoId);

      return {
        produto: produto?.nomeProduto || 'Produto não encontrado',
        metodo: produto?.metodos?.find((el) => el.id == metodoId)?.nome || 'Método não encontrado',
        date: activity.date,
        url: activity.url,
      };
    });

    this.activities.historico = mapedMetodos;
  }

  navigateTo(url: string): void {
    this._router.navigateByUrl(url);
  }
}
